<!--  -->
<template>
  <div class="order-muw-detail-container">
    <!-- 基础信息 -->
    <div class="section applicant-section">
      <div class="common-content">
        <p>
          <span class="label">产品名称 </span>
          <span class="value">{{ info.riskName }}</span>
        </p>
        <p>
          <span class="label">投保时间 </span>
          <span class="value">{{ info.insuredTime }}</span>
        </p>
        <p>
          <span class="label">投/被保人 </span>
          <span class="value">{{ info.appntName + "/" + info.insuredName }}</span>
        </p>
        <table class="letter-table" cellspacing="0">
          <tr>
            <th>核保对象</th>
            <th>核保状态</th>
            <th>核保结论</th>
          </tr>
          <tr v-if="info.app">
            <td>{{ "投保人" }}</td>
            <td>{{ info.app.status }}</td>
            <td>{{ info.app.conclusion }}</td>
          </tr>
          <tr v-if="info.ins">
            <td>{{ "被保人" }}</td>
            <td>{{ info.ins.status }}</td>
            <td>{{ info.ins.conclusion }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="section">
      <div title="投保人人核记录" v-if="info.app">
        <h4 v-if="info.app" class="block-title-muw" style="margin-top: 20px"><span>投保人人核记录</span></h4>
        <div class="result-box">
          <p>
            <strong>{{ info.app.conclusion }}</strong>
          </p>
          <span class="content" v-html="info.app.result"></span>
        </div>
        <div class="muw-table" v-for="(item, index) in info.app.letter" :key="index">
          <div class="header">
            <p class="label">核保决定函</p>
            <p class="value" :class="item.replyStatus == '0' ? 'notProcessed' : 'finish'">{{ item.replyDesc }}</p>
          </div>
          <div class="main-body">
            <div class="item">
              <p class="label">下发函件时间</p>
              <p class="value">{{ item.makeTime }}</p>
            </div>
            <div class="item">
              <p class="label">函件有效截止时间</p>
              <p class="value">{{ item.expireDate }}</p>
            </div>
            <div class="item">
              <p class="label">函件处理时间</p>
              <p class="value">{{ item.replyTime }}</p>
            </div>
          </div>
        </div>
      </div>
      <div title="被保人人核记录" v-if="info.ins">
        <h4 v-if="info.ins" class="margin-top-30 block-title-muw" style="margin-top: 30px"><span>被保人人核记录</span></h4>
        <div class="result-box">
          <p>
            <strong>{{ info.ins.conclusion }}</strong>
          </p>
          <span class="content" v-html="info.ins.result"></span>
        </div>
        <div class="muw-table" v-for="(item, index) in info.ins.letter" :key="index">
          <div class="header">
            <p class="label">核保决定函</p>
            <p class="value" :class="item.replyStatus == '0' ? 'notProcessed' : 'finish'">{{ item.replyDesc }}</p>
          </div>
          <div class="main-body">
            <div class="item">
              <p class="label">下发函件时间</p>
              <p class="value">{{ item.makeTime }}</p>
            </div>
            <div class="item">
              <p class="label">函件有效截止时间</p>
              <p class="value">{{ item.expireDate }}</p>
            </div>
            <div class="item">
              <p class="label">函件处理时间</p>
              <p class="value">{{ item.replyTime }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getManualDetail } from "../../api/api"
export default {
  data() {
    return {
      info: {
        riskName:"",
        insuredTime:"",
        appntName:"",
        insuredName:"",
      },
    }
  },
  created() {
    const { id } = this.$route.query
    this.id = id
    if (!id) {
      this.$toast("订单不存在！")
      return
    }
    this.getManualDetail(id)
  },
  methods: {
    async getManualDetail(orderId) {
      const toast = this.$toast.loading({
        overlay: true,
        forbidClick: true,
        message: "请稍后...",
        duration: 0,
      })
      const res = await getManualDetail({ orderId })
      toast.clear()
      this.info = res.data
    },
  },
}
</script>
<style lang="less" scoped>
.order-muw-detail-container {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 40px;
  min-height: 100vh;
  .muw-table {
    border: 1px solid #e5e5e5;
    margin-top: 30px;
    font-size: 28px;
    border-radius: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      border-bottom: 1px solid #e5e5e5;
      font-size: 30px;
      .label {
        font-size: 30px;
        color: #333;
        font-weight: 600;
      }
      .finish {
        color: #31c865;
        font-weight: 500;
      }
      .notProcessed {
        color: #f56c6c;
        font-weight: 500;
      }
    }
    .main-body {
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        .label,
        .value {
          font-size: 30px;
          line-height: 68px;
          color: #777;
        }
      }
    }
  }
  .block-title-muw {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 32px;
    // 伪元素小竖线
    &::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 24px;
      background-color: #ff8940;
      border-radius: 20px;
      margin-right: 10px;
    }
  }
  .result-box {
    background-color: #f9eee7;
    padding: 15px;
    border-radius: 6px;
    color: #ff6e00;
    font-size: 28px;
    .content {
      margin-top: 10px;
      font-size: 28px;
    }
  }

  .letter-table {
    tr th {
      border-top: 2px solid #eee;
    }
    tr th,
    tr td {
      border-right: 2px solid #eee;
      border-bottom: 2px solid #eee;
    }
    tr th:first-child,
    tr td:first-child {
      border-left: 2px solid #eee;
    }
    /* 分别设置 [左上, 右上, 右下, 左下] 的圆角 */
    tr:first-child th:first-child {
      border-top-left-radius: 20px;
    }
    tr:first-child th:last-child {
      border-top-right-radius: 20px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 20px;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 20px;
    }
    width: 100%;
    border-collapse: separate;
    color: #606266;
    margin-top: 10px;
    font-size: 24px;

    th,
    td {
      padding: 10px;
      text-align: center;
      color: #777777;
    }

    th {
      background-color: #f7f7f7;
      color: #333;
      font-weight: 700;
    }
  }
  .section {
    width: 702px;
    margin: 0 auto;

    .title {
      display: inline-block;
      position: relative;
      font-size: 36px;
      font-weight: 600;
      color: #333333;
      line-height: 45px;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 128px;
        height: 16px;
        background: linear-gradient(90deg, rgba(255, 234, 209, 0.34) 0%, #fed1a4 100%);
      }

      p {
        position: relative;
        z-index: 1;
      }
    }
  }
  .common-content {
    width: 702px;
    padding: 24px;
    background-color: #fff;
    margin-top: 16px;
    box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    position: relative;

    .product-name {
      font-weight: 500px;
      font-size: 36px;
    }

    p {
      display: flex;
      font-size: 30px;
      line-height: 42px;
      &:nth-child(n + 2) {
        margin-top: 18px;
      }
      .label {
        flex: 0 0 4.5em;
        color: #777777;
      }
      .value {
        flex: 1;
        margin-left: 30px;
        color: #333333;
      }
    }
  }
}
</style>
